const team = [
    {
        id: "",
        gender: "female",
        firstName: "Addy"
    },
    {
        id: "",
        gender: "female",
        firstName: "Abbi",
        lastName: "Conn"
    },
    {
        id: "",
        gender: "female",
        firstName: "Emma"
    },
    {
        id: "",
        gender: "female",
        firstName: "Jalyn"
    },
    {
        id: "",
        gender: "male",
        firstName: "Colt"
    },
    {
        id: "",
        gender: "female",
        firstName: "Carys"
    },
    {
        id: "",
        gender: "female",
        firstName: "Mikaya"
    },
    {
        id: "",
        gender: "male",
        firstName: "Cadence"
    },
    {
        id: "",
        gender: "male",
        firstName: "Everitt"
    },
    {
        id: "",
        gender: "male",
        firstName: "Caleb"
    },
    {
        id: "",
        gender: "male",
        firstName: "David"
    },
    {
        id: "",
        gender: "female",
        firstName: "Savannah"
    },
    {
        id: "",
        gender: "male",
        firstName: "Weston J.",
        lastName: "J"
    },
    {
        id: "",
        gender: "male",
        firstName: "Weston B.",
        lastName: "B"
    }
]

export default team
// <li>Addy</li>
/* <li>Abbi</li>
<li>Emma</li>
<li>Jalyn</li>
<li>Colton</li>
<li>Cole</li>
<li>Carys</li>
<li>Mikaya</li>
<li>Cadence</li>
<li>Everitt</li>
<li>Caleb</li>
<li>David</li>
<li>Savannah</li>
<li>Westin J.</li>
<li>Westin B.</li> */